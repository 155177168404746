/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Mon Nov 15 2021
 */
export default {
    props: {
        data: { type: Object, default: () => ([]) }
    }
}

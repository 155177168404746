<style scoped lang="less">
  .title {
    font-weight: 800;
    font-size: 15px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    th, td {
      text-align: center;
      border: 1px solid #999;
    }
  }
</style>

<template>
  <div>
    <div class="title">
      <span>{{data.type}}</span>
    </div>
    <div v-for="(prescription, i) in data.prescriptionData" :key="prescription.id + '-' + i">
      <table>
        <tr>
          <th>药品名称/规格</th>
          <th>开药量</th>
          <th>用法</th>
          <th>用药频率</th>
          <th>每次用量</th>
        </tr>
        <tr v-for="(medicine, i) in prescription.medicineData" :key="medicine.id + '-' + i">
          <td>{{medicine.medicineName}}</td>
          <td>{{medicine.volume}}</td>
          <td>{{medicine.medicineUsage}}</td>
          <td>{{medicine.usageFrequency}}</td>
          <td>{{medicine.timesVolume}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import mixins from './mixins'
export default {
  mixins: [mixins]
}
</script>
